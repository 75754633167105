import React, { useEffect, useState } from "react";
import App from "./App";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import { isEmpty } from "lodash";
const AppMain = () => {
  const [visiterId, setVisiterId] = useState("");
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (!isEmpty(localStorage.getItem("visiterId"))) {
      setVisiterId(localStorage.getItem("visiterId"));
      setLoader(false);
    } else {
      setVisiterId("");
      setLoader(false);
    }
  }, []);
  
  return !loader && !isEmpty(visiterId) ? (
    <App />
  ) : (
    <FpjsProvider
      loadOptions={{
        apiKey: "mbuLtPOM6OIcb4Hkgbzj",
      }}
    >
      <App />
    </FpjsProvider>
  );
};

export default AppMain;
