const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "AD",
    super_agent: "AD",
    agent: "AG",
    user: "CL",
  },
  new_user_status: {
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "admin",
    super_agent: "agent",
    agent: "user",
  },
  market_Status: {
    1: "Open",
    2: "In Active",
    3: "Suspended",
    4: "Closed",
    9: "Ball Start",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  settingHeading: {
    bookmaker: "Bookmaker Listing",
    betFaire: "Bet Fair Listing",
    fancy: "Fancy Listing",
    sportBook: "Premium Fancy Listing",
  },
  messageType: {
    downline: "Downline",
    all: "All",
    user: "User",
  },
  settingArray: [
    {
      type: "betFaire",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "bookmaker",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "fancy",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "sportBook",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
  ],
  betCheckData: [
    {
      value: "Cricket",
      label: "4",
    },
    {
      value: "Tennis",
      label: "2",
    },
    {
      value: "Soccer",
      label: "1",
    },
    {
      value: "Casino",
      label: "3",
    },
  ],
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  betCheckDataLabel: [
    {
      value: "Cricket",
      label: "cricket",
    },
    {
      value: "Tennis",
      label: "tennis",
    },
    {
      value: "Soccer",
      label: "soccer",
    },
    {
      value: "Casino",
      label: "casino",
    },
  ],
  betCheckDataInner: [
    {
      value: "Bet Fair",
      label: "betfair",
    },
    {
      value: "Bookmaker",
      label: "bookmaker",
    },
    {
      value: "Fancy",
      label: "fancy",
    },
    {
      value: "SportsBook",
      label: "sportBook",
    },
    {
      value: "Tie",
      label: "tie",
    },
    {
      value: "Toss",
      label: "toss",
    },
  ],
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
  homeCasino: [
    {
      casino: "kingmaker",
      name: "Baccarat",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType: "KM-TABLE-026",
      image: "assets/images/home/KM-TABLE-026.png",
    },
    {
      casino: "kingmaker",
      name: "Coin Toss",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType: "KM-TABLE-036",
      image: "assets/images/home/KM-TABLE-036.png",
    },
  ],

  gameSlot: [
    {
      img: "../assets/images/slot/PP-SLOT-002.webp",
      name: "Gates of Olympus",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-002",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-082.webp",
      name: "Sweet Bonanza",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-082",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-189.webp",
      name: "Starlight Princess",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-189",
      cateogry: "PP",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PP-SLOT-183.webp",
      name: "5 Lions Megaways",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-183",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-080.webp",
      name: "5 Lions Gold",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-080",
      cateogry: "PP",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PP-SLOT-049.webp",
      name: "Great Rhino Megaways",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-049",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-069.webp",
      name: "Fire Strike",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-069",
      cateogry: "PP",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PP-SLOT-067.webp",
      name: "Sweet Bonanza Xmas",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-067",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-015.webp",
      name: "Bonanza Gold",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-015",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-176.webp",
      name: "Buffalo King Megaways",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-176",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-181.webp",
      name: "The Magic Cauldron - Enchanted Brew",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-181",
      cateogry: "PP",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PP-SLOT-046.webp",
      name: "Fruit Party",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-046",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-187.webp",
      name: "Pyramid Bonanza",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-187",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-204.webp",
      name: "Candy Village",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-204",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-126.webp",
      name: "3 Kingdoms - Battle of Red Cliffs",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-126",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-104.webp",
      name: "5 Lions",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-104",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-072.webp",
      name: "John Hunter and the Tomb of the Scarab Queen",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-072",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-107.webp",
      name: "Joker's Jewels",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-107",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-025.webp",
      name: "Gems Bonanza",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-025",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-210.webp",
      name: "Santa's Wonderland",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-210",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-233.webp",
      name: "Wild West Gold Megaways",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-233",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-234.webp",
      name: "Spirit of Adventure",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-234",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-235.webp",
      name: "Fire Strike 2",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-235",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-236.webp",
      name: "Barn Festival",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-236",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-237.webp",
      name: "Chicken Chase",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-237",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-238.webp",
      name: "Goblin Heist Powernudge",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-238",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-239.webp",
      name: "Eye of Cleopatra",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-239",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-240.webp",
      name: "The Great Stick-Up",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-240",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-296.webp",
      name: "Aztec Blaze",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-296",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-297.webp",
      name: "Starlight Christmas",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-297",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-298.webp",
      name: "Towering Fortunes",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-298",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-299.webp",
      name: "Firebird Spirit",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-299",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PP-SLOT-300.webp",
      name: "Pirate Golden Age",
      platForm: "PP",
      gameType: "SLOT",
      casinoType: "PP-SLOT-300",
      cateogry: "PP",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/KM-SLOT-001.webp",
      name: "Sugar Blast",
      platForm: "KINGMAKER",
      gameType: "SLOT",
      casinoType: "KM-SLOT-001",
      cateogry: "KINGMAKER",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-003.webp",
      name: "777",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-003",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-004.webp",
      name: "Get Money",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-004",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-012.webp",
      name: "Over Dragon's Gate",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-012",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-002.webp",
      name: "Phoenix",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-002",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-011.webp",
      name: "Diamond Mogul",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-011",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-005.webp",
      name: "Rich Now",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-005",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-008.webp",
      name: "Dracula",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-008",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-020.webp",
      name: "Bust Treasury",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-020",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-001.webp",
      name: "Coin Cat",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-001",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-015.webp",
      name: "Egypt Oracle",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-015",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-018.webp",
      name: "Rich Lion",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-018",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-017.webp",
      name: "Candy Dynasty",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-017",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-007.webp",
      name: "Doggy Wealth",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-007",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-016.webp",
      name: "Caishen Coming",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-016",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-006.webp",
      name: "Chef Lady",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-006",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-009.webp",
      name: "Maya King",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-009",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-010.webp",
      name: "Pirate King",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-010",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-013.webp",
      name: "Great Lion",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-013",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-014.webp",
      name: "Ultra Treasure",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-014",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/DRAGOONSOFT-SLOT-019.webp",
      name: "Monkey King",
      platForm: "DRAGOONSOFT",
      gameType: "SLOT",
      casinoType: "DRAGOONSOFT-SLOT-019",
      cateogry: "DRAGOONSOFT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-106.webp",
      name: "Fiery Sevens Exclusive",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-106",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-074.webp",
      name: "Mega7",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-074",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-116.webp",
      name: "Legacy Of Kong Maxways",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-116",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-069.webp",
      name: "888",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-069",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-112.webp",
      name: "Fruits Mania",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-112",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-076.webp",
      name: "DoubleFlame",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-076",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-110.webp",
      name: "Space Conquest",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-110",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-081.webp",
      name: "Triple Panda",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-081",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-077.webp",
      name: "Book of Myth",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-077",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-095.webp",
      name: "Poker Ways",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-095",
      cateogry: "SPADE",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/SG-SLOT-086.webp",
      name: "Mayan Gems",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-086",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-075.webp",
      name: "JokersTreasure",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-075",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-030.webp",
      name: "5FortuneSA",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-030",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-103.webp",
      name: "Sexy Vegas",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-103",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-107.webp",
      name: "Lucky Koi Exclusive",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-107",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-082.webp",
      name: "Gold Panther",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-082",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-032.webp",
      name: "Golden Lotus SE",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-032",
      cateogry: "SPADE",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/SG-SLOT-105.webp",
      name: "Royale House",
      platForm: "SPADE",
      gameType: "SLOT",
      casinoType: "SG-SLOT-105",
      cateogry: "SPADE",
      type: "SLOT",
    },
    // Slot End SPADE (casino Done) -----

    // Slot Start PT (casino Done) -----
    {
      img: "../assets/images/slot/PT-SLOT-082.webp",
      name: "Football Rules",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-082",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-070.webp",
      name: "Heart of the Frontier",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-070",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-071.webp",
      name: "Thai Paradise",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-071",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-072.webp",
      name: "Legacy of the Wild",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-072",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-073.webp",
      name: "Archer",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-073",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-001.webp",
      name: "Triple Monkey",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-001",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-003.webp",
      name: "Buffalo Blitz",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-003",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-006.webp",
      name: "Great Blue",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-006",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-004.webp",
      name: "Captain Treasure",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-004",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-005.webp",
      name: "Long Long Long",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-005",
      cateogry: "PT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PT-SLOT-009.webp",
      name: "Golden Tour",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-009",
      cateogry: "PT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PT-SLOT-010.webp",
      name: "Funky Monkey",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-010",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-011.webp",
      name: "Highway Kings",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-011",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-014.webp",
      name: "Zhao Cai Tong Zi",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-014",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-012.webp",
      name: "Zhao Cai Jin Bao",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-012",
      cateogry: "PT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PT-SLOT-013.webp",
      name: "Jin Qian Wa",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-013",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-015.webp",
      name: "Ugga Bugga",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-015",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-016.webp",
      name: "Safari Heat",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-016",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-002.webp",
      name: "Arowanas Luck",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-002",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-019.webp",
      name: "Gem Queen",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-019",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-023.webp",
      name: "Chaoji 888",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-023",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-008.webp",
      name: "Blue Wizard",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-008",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-018.webp",
      name: "Bonus Bears",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-018",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-017.webp",
      name: "Buffalo Blitz II",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-017",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-007.webp",
      name: "Sky Queen",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-007",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-020.webp",
      name: "Feng Kuang Ma Jiang",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-020",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-021.webp",
      name: "Epic Ape",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-021",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-022.webp",
      name: "Ni Shu Shen Me",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-022",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-024.webp",
      name: "Vacation Station Deluxe",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-024",
      cateogry: "PT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PT-SLOT-025.webp",
      name: "Eternal Lady",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-025",
      cateogry: "PT",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/PT-SLOT-026.webp",
      name: "Golden Macaque",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-026",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-027.webp",
      name: "Fei Long Zai Tian",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-027",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-028.webp",
      name: "Yun Cong Long",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-028",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-029.webp",
      name: "Jinfu Long",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-029",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-030.webp",
      name: "Hot Gems",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-030",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-031.webp",
      name: "Lie Yan Zuan Shi",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-031",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-032.webp",
      name: "Dolphin Reef",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-032",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-033.webp",
      name: "Jinns Moon",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-033",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-034.webp",
      name: "Tiger, Turtle, Dragon, Phoenix",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-034",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-035.webp",
      name: "Heart of the Jungle",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-035",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-036.webp",
      name: "Heavenly Ruler",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-036",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-037.webp",
      name: "Ox Riches",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-037",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-038.webp",
      name: "Ice Cave",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-038",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-039.webp",
      name: "Queen of Wands",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-039",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-040.webp",
      name: "Si Xiang",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-040",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-041.webp",
      name: "Panther Moon",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-041",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-043.webp",
      name: "Asian Fantasy",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-043",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-044.webp",
      name: "Fortune Lions",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-044",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-045.webp",
      name: "Hot KTV",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-045",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-046.webp",
      name: "Fortune Day",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-046",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-047.webp",
      name: "Ri Ri Jin Cai",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-047",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-048.webp",
      name: "Sun WuKong",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-048",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-049.webp",
      name: "Ji Xiang 8",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-049",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-050.webp",
      name: "Jade Emperor Yu Huang Da Di",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-050",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-051.webp",
      name: "Halloween Fortune 2",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-051",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-060.webp",
      name: "Chinese Kitchen",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-060",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-061.webp",
      name: "Geisha Story",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-061",
      cateogry: "PT",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/PT-SLOT-062.webp",
      name: "Fortunes of the Fox",
      platForm: "PT",
      gameType: "SLOT",
      casinoType: "PT-SLOT-062",
      cateogry: "PT",
      type: "SLOT",
    },
    // Slot End PT (casino Done) -----

    // Slot Start JDB (casino Done) -----
    {
      img: "../assets/images/slot/JDB-SLOT-108.webp",
      name: "Moneybags Man 2",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-108",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-106.webp",
      name: "FortuneNeko",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-106",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-107.webp",
      name: "Trump Card",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-107",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-105.webp",
      name: "BookOfMystery",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-105",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-001.webp",
      name: "Burglar",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-001",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-003.webp",
      name: "Chef Doeuvre",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-003",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-004.webp",
      name: "Lucky Miner",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-004",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-005.webp",
      name: "Candy Land",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-005",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-006.webp",
      name: "Crazy Scientist",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-006",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-007.webp",
      name: "Super Dumpling",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-007",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-008.webp",
      name: "Cash Man",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-008",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-009.webp",
      name: "Wild Beauty",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-009",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-010.webp",
      name: "Flirting Scholar Tang",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-010",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-011.webp",
      name: "Winning Mask",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-011",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-012.webp",
      name: "Wu kong",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-012",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-013.webp",
      name: "Llama Adventure",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-013",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-014.webp",
      name: "Formosa Bear",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-014",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-015.webp",
      name: "Lucky Qilin",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-015",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-016.webp",
      name: "New Year",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-016",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-017.webp",
      name: "Four Treasures",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-017",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-018.webp",
      name: "Open Sesame",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-018",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-019.webp",
      name: "Banana Saga",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-019",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-020.webp",
      name: "Olympian Temple",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-020",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-021.webp",
      name: "Dancing Pa Pa",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-021",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-022.webp",
      name: "Dragon King",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-022",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-023.webp",
      name: "Magic Show",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-023",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-024.webp",
      name: "Cock Fight",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-024",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-025.webp",
      name: "Dragon",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-025",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-026.webp",
      name: "Rooster In Love",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-026",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-027.webp",
      name: "Monkey King",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-027",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-028.webp",
      name: "Moonlight Treasure",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-028",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-029.webp",
      name: "Mahjong",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-029",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-030.webp",
      name: "Guan Gong",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-030",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-031.webp",
      name: "Billionaire",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-031",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-032.webp",
      name: "Dragon Warrior",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-032",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-033.webp",
      name: "Kingsman",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-033",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-034.webp",
      name: "Curvy Magician",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-034",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-035.webp",
      name: "RollingIn Money",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-035",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-036.webp",
      name: "Chef Panda",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-036",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-045.webp",
      name: "Lucky Dragons",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-045",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-046.webp",
      name: "Lucky Lion",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-046",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-047.webp",
      name: "Coffeeholics",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-047",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-048.webp",
      name: "Napoleon",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-048",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-049.webp",
      name: "Lucky Phoenix",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-049",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-050.webp",
      name: "WinningMask II",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-050",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-051.webp",
      name: "Open Sesame II",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-051",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-052.webp",
      name: "Flirting Scholar Tang II",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-052",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-053.webp",
      name: "Fortune Horse",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-053",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-054.webp",
      name: "Xi Yang Yang",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-054",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-055.webp",
      name: "Panda Panda",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-055",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-056.webp",
      name: "Zelda",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-056",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-057.webp",
      name: "Mr Bao",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-057",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-058.webp",
      name: "One Punch Man",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-058",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-059.webp",
      name: "Guardians of The Galaxy",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-059",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-060.webp",
      name: "Street Fighter",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-060",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-061.webp",
      name: "Star Wars",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-061",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-062.webp",
      name: "War of Beauty",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-062",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-063.webp",
      name: "Daji",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-063",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-064.webp",
      name: "Poker King",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-064",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-065.webp",
      name: "Lucky Racing",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-065",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-066.webp",
      name: "Birds Party",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-066",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-067.webp",
      name: "Go Lai Fu",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-067",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-068.webp",
      name: "Gems Gems",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-068",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-069.webp",
      name: "Dragons World",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-069",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-070.webp",
      name: "Super Niubi",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-070",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-071.webp",
      name: "Egypt Treasure",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-071",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-072.webp",
      name: "Fortune Treasure",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-072",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-073.webp",
      name: "Pirate Treasure",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-073",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-074.webp",
      name: "Mjoinir",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-074",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-075.webp",
      name: "Treasure Bowl",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-075",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-076.webp",
      name: "Funky King Kong",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-076",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-077.webp",
      name: "Super Niubi Deluxe",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-077",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-078.webp",
      name: "Super Niubi",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-078",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-079.webp",
      name: "Lucky Fuwa",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-079",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-080.webp",
      name: "Inca Empire",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-080",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-081.webp",
      name: "Ninja Rush",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-081",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-082.webp",
      name: "Sun Archer",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-082",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-083.webp",
      name: "Mystery of Nine Tales",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-083",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-084.webp",
      name: "Golden Disco",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-084",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-085.webp",
      name: "Miner Babe",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-085",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-086.webp",
      name: "Moneybags Man",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-086",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-087.webp",
      name: "Double Wilds",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-087",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-088.webp",
      name: "Pop Pop Fruity",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-088",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-089.webp",
      name: "Spindrift",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-089",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-090.webp",
      name: "Spindrift 2",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-090",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-091.webp",
      name: "Jungle Jungle",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-091",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-092.webp",
      name: "Dragons Gate",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-092",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-093.webp",
      name: "Lucky Diamond",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-093",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-094.webp",
      name: "Kong",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-094",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-095.webp",
      name: "NinjaX",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-095",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-096.webp",
      name: "Rex Brothers",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-096",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-097.webp",
      name: "Wonder Elephant",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-097",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-098.webp",
      name: "MarvelousIV",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-098",
      cateogry: "JDB",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JDB-SLOT-099.webp",
      name: "Maya Gold Crazy",
      platForm: "JDB",
      gameType: "SLOT",
      casinoType: "JDB-SLOT-099",
      cateogry: "JDB",
      type: "SLOT",
    },
    // Slot End JDB (casino Done) -----

    // Slot Start FC (casino Done) -----

    {
      img: "../assets/images/slot/FC-SLOT-025.webp",
      name: "SUGAR BANG BANG",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-025",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-021.webp",
      name: "Lucky Fortunes",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-021",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-020.webp",
      name: "Robin Hood",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-020",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-019.webp",
      name: "GLORY OF ROME",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-019",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-001.webp",
      name: "GOLDEN PANTHER",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-001",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-002.webp",
      name: "THREE LITTLE PIGS",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-002",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-003.webp",
      name: "HOT POT PARTY",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-003",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-004.webp",
      name: "NIGHT MARKET",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-004",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-005.webp",
      name: "PONG PONG HU",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-005",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-006.webp",
      name: "PANDA DRAGON BOAT",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-006",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-007.webp",
      name: "CHINESE NEW YEAR",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-007",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-008.webp",
      name: "FORTUNE KOI",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-008",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-009.webp",
      name: "HAPPY DUO BAO",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-009",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-010.webp",
      name: "	DA LE MEN",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-010",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-011.webp",
      name: "ANIMAL RACING",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-011",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-012.webp",
      name: "LUXURY GOLDEN PANTHER",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-012",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-013.webp",
      name: "MAGIC BEANS",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-013",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-014.webp",
      name: "COWBOYS",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-014",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-015.webp",
      name: "WAR OF THE UNIVERSE",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-015",
      cateogry: "FC",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/FC-SLOT-016.webp",
      name: "TREASURE CRUISE",
      platForm: "FC",
      gameType: "SLOT",
      casinoType: "FC-SLOT-016",
      cateogry: "FC",
      type: "SLOT",
    },
    // Slot End FC (casino Done) -----

    // Slot Start JILI (casino Done) -----
    {
      img: "../assets/images/slot/JILI-SLOT-063.webp",
      name: "World Cup",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-063",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-056.webp",
      name: "Happy Taxi",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-056",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-057.webp",
      name: "Gold Rush",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-057",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-054.webp",
      name: "Crazy Pusher",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-054",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-053.webp",
      name: "Book of Gold",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-053",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-071.webp",
      name: "Neko Fortune",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-071",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-051.webp",
      name: "Mega Ace",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-051",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-052.webp",
      name: "Thor X",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-052",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-002.webp",
      name: "Hot Chilli",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-002",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-003.webp",
      name: "Chin Shi Huang",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-003",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-004.webp",
      name: "War Of Dragons",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-004",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-005.webp",
      name: "Fortune Tree",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-005",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-006.webp",
      name: "Lucky Ball",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-006",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-007.webp",
      name: "Hyper Burst",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-007",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-008.webp",
      name: "Shanghai Beauty",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-008",
      cateogry: "JILI",
      type: "SLOT",
    },

    {
      img: "../assets/images/slot/JILI-SLOT-009.webp",
      name: "Fa Fa Fa",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-009",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-010.webp",
      name: "God Of Martial",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-010",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-012.webp",
      name: "Hawaii Beauty",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-012",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-013.webp",
      name: "SevenSevenSeven",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-013",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-014.webp",
      name: "Crazy777",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-014",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-015.webp",
      name: "Bubble Beauty",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-015",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-016.webp",
      name: "Bao boon chin",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-016",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-017.webp",
      name: "Crazy FaFaFa",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-017",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-018.webp",
      name: "XiYangYang",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-018",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-019.webp",
      name: "FortunePig",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-019",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-020.webp",
      name: "Candy Baby",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-020",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-021.webp",
      name: "Diamond Party",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-021",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-022.webp",
      name: "Fengshen",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-022",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-023.webp",
      name: "Golden Bank",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-023",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-024.webp",
      name: "Lucky Goldbricks",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-024",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-025.webp",
      name: "Dragon Treasure",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-025",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-026.webp",
      name: "Charge Buffalo",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-026",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-027.webp",
      name: "Super Ace",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-027",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-028.webp",
      name: "Jungle King",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-028",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-029.webp",
      name: "Money Coming",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-029",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-030.webp",
      name: "Golden Queen",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-030",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-031.webp",
      name: "Boxing King",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-031",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-032.webp",
      name: "Matka India",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-032",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-037.webp",
      name: "Lucky Coming",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-037",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-038.webp",
      name: "Pharaoh Treasure",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-038",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-039.webp",
      name: "Secret Treasure",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-039",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-040.webp",
      name: "RomaX",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-040",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-041.webp",
      name: "Super Rich",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-041",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-042.webp",
      name: "Golden Empire",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-042",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-043.webp",
      name: "Fortune Gems",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-043",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-044.webp",
      name: "Party Night",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-044",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-045.webp",
      name: "Crazy Hunter",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-045",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-046.webp",
      name: "Magic Lamp",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-046",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-047.webp",
      name: "TWINWINS",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-047",
      cateogry: "JILI",
      type: "SLOT",
    },
    {
      img: "../assets/images/slot/JILI-SLOT-048.webp",
      name: "Agent Ace",
      platForm: "JILI",
      gameType: "SLOT",
      casinoType: "JILI-SLOT-048",
      cateogry: "JILI",
      type: "SLOT",
    },
  ],
};

export default obj;
