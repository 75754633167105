import React, { useEffect, useRef, useState, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import OtpInput from "react18-input-otp";
const Login = () => {
  const navigate = useNavigate();
  let { loginUser, user } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });
  const [getValidateCode, setValidateCode] = useState("");
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  useEffect(() => {
    // if (step == 1) {
    changeCode();
    // }
  }, []);
  const onSubmit = (data) => {
    if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
      // setStep(2);
    }
  };

  const canvasRef = useRef(null);

  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "60px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 10, 50);
    setValidateCode(code);
  };

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);

  const onSubmitOTP = (body) => {};
  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <section className="login-sec">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="main-center">
              <div className="login_box m-auto">
                <div className="login_left">
                  <span className="login_logo">
                    <img src="assets/images/melbets-logo.png" alt="" />
                  </span>
                </div>

                {step === 1 && (
                  <div className="form_outer">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                    

                      <h2 className="text-white">Agent login </h2>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          className={errors.username ? "is-invalid" : ""}
                          {...register("username", {
                            required: "Please enter Username",
                          })}
                        />
                        {errors.username && errors.username.message && (
                          <label className="invalid-feedback text-left">
                            {errors.username.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className={errors.password ? "is-invalid" : ""}
                          {...register("password", {
                            required: "Please enter password",
                          })}
                        />
                        {errors.password && errors.password.message && (
                          <label className="invalid-feedback text-left">
                            {errors.password.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Group className="position-relative mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Validate Code"
                          maxLength="4"
                          className={errors.validateCode ? "is-invalid" : ""}
                          {...register("validateCode", {
                            required: "Please enter validate code",
                            validate: {
                              validate: (value) =>
                                parseInt(value) === parseInt(getValidateCode) ||
                                "Invalid validate code",
                            },
                          })}
                        />
                        <canvas ref={canvasRef} onClick={changeCode} />
                        {/* <canvas id="myCanvas" width="200" height="100" /> */}
                        {errors.validateCode && errors.validateCode.message && (
                          <label className="invalid-feedback text-left">
                            {errors.validateCode.message}
                          </label>
                        )}
                      </Form.Group>
                      <p
                        style={{
                          color: "white",
                          display: "flex",
                          justifyContent: "flex-end",
                          fontSize: "15px",
                          marginBottom: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/forgotPassword")}
                      >
                        Forgot Password ?
                      </p>
                      <Button type="submit" className="green-btn theme-btn">
                        Login
                        <span>
                          <img src="assets/images/loginicon.svg" />
                        </span>
                      </Button>
                    </Form>
                    
                  </div>
                )}
                {step === 2 && (
                  <div className="form_outer">
                    <Form onSubmit={handleSubmit(onSubmitOTP)}>
                      <h2 className="text-white">Verify OTP </h2>
                      <Controller
                        className="form-group d-flex"
                        control={control}
                        name="otp"
                        rules={{
                          required: "Please enter 4 digit OTP",
                          validate: (value) => {
                            if (value?.toString()?.length == 4) {
                              return true;
                            } else {
                              return "Please enter 4 digit OTP";
                            }
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <>
                            <OtpInput
                              {...field}
                              numInputs={4}
                              isInputNum={true}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              shouldAutoFocus={true}
                              inputStyle={{
                                width: "88%",
                                height: "52px",
                                borderRadius: "7px",
                                border: "1px solid #ced4da",
                              }}
                              separator={<span> </span>}
                            />
                          </>
                        )}
                      />
                      {errors?.otp?.message && (
                        <div
                          className="text-danger"
                          style={{ marginTop: "5px" }}
                        >
                          {errors?.otp?.message}
                        </div>
                      )}
                      <Button type="submit" className="green-btn theme-btn">
                        Verify
                        <span>
                          <img src="assets/images/loginicon.svg" />
                        </span>
                      </Button>
                    </Form>
                  </div>
                )}
              </div>

              <div id="supportWrap" className="support-wrap">
                <div className="support-service">
                  <a
                    id="support_whatsapp"
                    href="#"
                    className="support-telegram open"
                  >
                    <img src="assets/images/Telegram_logo.png" title="WhatsApp" />
                  </a>
                </div>

                <div className="support-info">
                  <div
                    id="supportDetail_whatsapp"
                    className="support-detail open"
                  >
                    {/* <a href="https://t.me/+971508065313" target="_blank">+971508065313</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Login;
