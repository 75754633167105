import React, { useState, useEffect, useContext } from "react";
import { Container, Table, Button, Form } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";
import obj from "../utils/helpers";

const SearchVisitor = () => {
  let { user } = useContext(AuthContext);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
  });

  const LogData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.searchUser,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setCurrentItems(response_users.results || []);
      }
    }
  };
  const handleClick = () => {
    setSearchParams((prevState) => {
      return {
        ...prevState,
        keyword: keyword,
        type: "visitor",
      };
    });
  };

  useEffect(() => {
    setPageCount(currentItems?.totalPages || []);
  }, [currentItems]);

  useEffect(() => {
    if (search_params.keyword) {
      LogData();
    }
  }, [search_params]);

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <div className="db-sec">
            <h2 className="common-heading">Search Visitor</h2>
          </div>

          <div className="find-member-sec">
            <Form className="mb-4">
              <Form.Group className="position-relative mb-2">
                <Form.Control
                  type="text"
                  className="small_form_control"
                  placeholder="Enter User Name..."
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                />
                <i className="fas fa-search"></i>
              </Form.Group>
              <div className="d-flex flex-wrap block-search-sec">
                <Button
                  className="mb-2 mx-1 theme_light_btn"
                  onClick={handleClick}
                >
                  Search Visitor
                </Button>
              </div>
            </Form>

            <div className="inner-wrapper">
              <div className="common-container">
                <div className="account-table batting-table">
                  <div className="responsive">
                    <Table className="banking_detail_table">
                      <thead>
                        <tr>
                          <th scope="col">Super Agent</th>
                          <th scope="col">Agent</th>
                          <th scope="col">Status</th>
                          <th scope="col">User</th>
                          <th scope="col">Balance</th>
                          <th scope="col">Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems &&
                          currentItems?.map((item, key) => (
                            <tr key={key}>
                              <td>
                                {item?.userType === "super_agent"
                                  ? item?.username
                                  : item?.super_agent?.username
                                  ? item?.super_agent?.username
                                  : "-"}
                              </td>
                              <td>
                                {item?.userType === "agent"
                                  ? item?.username
                                  : item?.agent?.username
                                  ? item?.agent?.username
                                  : "-"}
                              </td>
                              <td>{item?.status}</td>
                              <td>
                                {item?.userType === "user"
                                  ? item?.username
                                  : "-"}
                                {item?.pwd ? "- Pass(" + item?.pwd + ")" : ""}
                              </td>
                              <td>{item?.totalCoins}</td>
                              <td>{obj?.dateFormat(item?.createdAt)}</td>
                            </tr>
                          ))}
                        {isEmpty(currentItems) ? (
                          <tr>
                            <td colSpan={9}>No records found</td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SearchVisitor;
