import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "wc-toast";
import { Controller, useForm } from "react-hook-form";
import { apiPost, apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { isEmpty, pick, startCase } from "lodash";
import constants from "../../utils/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react18-input-otp";
const Add = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      timeZone: "Asia/Kolkata",
      commission: 0,
      exposureLimit: 1000,
    },
  });
  const inputRef = useRef(null);
  const [password_same, set_password_same] = useState();
  const [isLoader, setLoader] = useState(false);
  const [metaData, setMetaData] = useState();
  const onSubmit = async (request) => {
    let obj;
    if (props.slug === "agent") {
      obj = {
        // mobile: request?.mobile?.substring(
        //   inputRef?.current?.state.selectedCountry?.countryCode?.length,
        //   request?.mobile?.toString()?.length
        // ),
        // countryCode: inputRef?.current?.state.selectedCountry?.countryCode,
        username: request?.mobile,
        password: request?.pin,
        timeZone: request?.timeZone,
      };
    } else if (
      (constants.user_next_status[props.slug] == "agent" &&
        props?.user?.userType == "super_agent") ||
      (constants.user_next_status[props.slug] == "super_agent" &&
        props?.user?.userType == "sub_owner")
    ) {
      obj = pick(request, [
        "website",
        "email",
        "username",
        "password",
        "userType",
        "AffiliatedAgentCommission",
        //"firstName",
        //"lastName",
        //"phone",
        "timeZone",
        "commission",
      ]);
    } else if (props.slug == "sub_owner") {
      obj = pick(request, [
        "website",
        "email",
        "username",
        "password",
        "userType",
        "commission",
        //"firstName",
        //"lastName",
        //"phone",
        "timeZone",
      ]);
    } else {
      obj = pick(request, [
        "website",
        "email",
        "username",
        "password",
        "userType",
        //"firstName",
        //"lastName",
        //"phone",
        "timeZone",
        "commission",
      ]);
    }
    setLoader(true);
    set_password_same(true);
    if (request.password !== request.confirmPassword) {
      set_password_same(false);
    } else {
      /**
       * ! request.userType = "super_admin";
       * * This should be managed by Backend.
       */
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.addProfile,
          { ...obj, createdBy: props.id }
        );
        if (status === 200) {
          if (response_users.success) {
            setLoader(false);
            props.setShowModel();
            props.refreshUsers();
            toast.success(response_users.message);
            reset();
          } else {
            setLoader(false);
            toast.error(response_users.message);
          }
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      } catch (err) {
        setLoader(false);
        toast.error(err.response.data.message);
      }
    }
  };

  const getMetaData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.metaData);
    if (status === 200) {
      if (response_users.success) {
        setMetaData(response_users.results);
      }
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  let passwordCheck =
    props.slug == "agent"
      ? {
          required: "Please enter Pin",
          minLength: {
            value: 6,
            message: "Pin should contain atleast 6 digit number",
          },
          maxLength: {
            value: 6,
            message: "Pin should contain maximum 6 digit number",
          },
          pattern: {
            value: /^(?:[1-9]\d*|\d)$/,
            message: "Please enter 6 digit number",
          },
        }
      : {
          required: "Please enter password",
          minLength: {
            value: 8,
            message: "Password should contain atleast 8 characters",
          },
          maxLength: {
            value: 16,
            message: "Password should contain maximum 16 characters",
          },
        };

  let passwordCheckConfirm =
    props?.slug == "agent"
      ? {
          required: {
            value: true,
            message: "Please enter confirm pin",
          },
          validate: (value) => {
            if (value === "") {
              return true;
            }
            if (!isEmpty(watch("password"))) {
              if (value == watch("password")) {
                return true;
              } else {
                return "Pin and confirm pin does not match.";
              }
            }
          },
        }
      : {
          required: "Please enter password",
          minLength: {
            value: 8,
            message: "Password should contain atleast 8 characters",
          },
          maxLength: {
            value: 16,
            message: "Password should contain maximum 16 characters",
          },
        };
  return (
    <div>
      <Modal
        show={true}
        onHide={() => {
          props.setShowModel();
        }}
        className="super-admin-modal"
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="modal-title-status">
            Add {startCase(constants.user_next_status[props.slug])}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="super-admin-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              {/* {constants.user_next_status[props.slug] == "sub_owner" && (
                <Col md={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Select Website</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Select
                        aria-label="Default select example"
                        className={errors.website ? " is-invalid " : ""}
                        {...register("website", {
                          required: "Please select website",
                        })}
                      >
                        <option value="">Please select webiste</option>
                        {metaData &&
                          metaData["websiteList"] &&
                          metaData["websiteList"].map((item, key) => (
                            <option value={item.domain} key={key}>
                              {item.domain}
                            </option>
                          ))}
                      </Form.Select>
                      {errors.website && errors.website.message && (
                        <label className="invalid-feedback text-left">
                          {errors.website.message}
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              )} */}
              {props.slug != "agent" && (
                <Col sm={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Email</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        className={errors.email ? " is-invalid " : ""}
                        {...register("email", {
                          required: "Please enter email",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address.",
                          },
                        })}
                      />
                      {errors.email && errors.email.message && (
                        <label className="invalid-feedback text-left">
                          {errors.email.message}
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              )}

              {props.slug == "agent" ? (
                <Col sm={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Phone Number</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Controller
                        className="form-group d-flex"
                        control={control}
                        name="mobile"
                        rules={{
                          required: "Please enter mobile number.",
                          validate: (value) => {
                            let inputValue = value
                              ?.toString()
                              ?.slice(
                                inputRef?.current?.state?.selectedCountry
                                  ?.countryCode?.length,
                                value?.length
                              );
                            if (inputValue?.length < 8) {
                              return "Mobile number must contain 5 digit";
                            } else if (inputValue?.length > 13) {
                              return "Mobile number should not exceed 13 digit";
                            } else {
                              return true;
                            }
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <PhoneInput
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                              autoFocus: true,
                            }}
                            ref={inputRef}
                            inputStyle={{
                              // width: "100%",
                              height: "38px",
                            }}
                            country={"bd"}
                            enableSearch
                            countryCodeEditable={false}
                          />
                        )}
                      />
                      {errors?.mobile?.message && (
                        <div className="text-danger">
                          {errors?.mobile?.message}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              ) : (
                <Col sm={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Username</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        placeholder="Enter username"
                        className={errors.username ? " is-invalid " : ""}
                        {...register("username", passwordCheck)}
                      />
                      {errors.username && errors.username.message && (
                        <label className="invalid-feedback text-left">
                          {errors.username.message}
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              )}

              {props.slug == "agent" ? (
                <>
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={3}>
                        <Form.Label>{"New Pin"}</Form.Label>
                      </Col>
                      <Col md={9}>
                        {" "}
                        <Controller
                          className="form-group d-flex"
                          control={control}
                          name="pin"
                          rules={{
                            required: "Please enter pin.",
                            validate: (value) => {
                              if (value?.toString().length !== 6) {
                                return "Please enter 6 digit pin";
                              }
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <OtpInput
                              numInputs={6}
                              {...field}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              ref={inputRef}
                              isInputNum={true}
                              inputStyle={{
                                width: "90%",
                                height: "40px",
                                borderRadius: "7px",
                                border: "1px solid #ced4da",
                              }}
                              separator={<span> </span>}
                            />
                          )}
                        />
                        {errors?.pin?.message && (
                          <div className="text-danger">
                            {errors?.pin?.message}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>{" "}
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={3}>
                        <Form.Label>{"Confirm Pin"}</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Controller
                          className="form-group d-flex"
                          control={control}
                          name="confirmPin"
                          rules={{
                            required: "Please enter confirm pin.",
                            validate: (value) => {
                              if (value?.toString().length !== 6) {
                                return "Please enter 6 digit pin";
                              }
                              if (!isEmpty(watch("pin"))) {
                                if (value == watch("pin")) {
                                  return true;
                                } else {
                                  return "new pin and confirm pin does not match.";
                                }
                              }
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <OtpInput
                              numInputs={6}
                              {...field}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              ref={inputRef}
                              isInputNum={true}
                              inputStyle={{
                                width: "90%",
                                height: "40px",
                                borderRadius: "7px",
                                border: "1px solid #ced4da",
                              }}
                              separator={<span> </span>}
                            />
                          )}
                        />
                        {errors?.confirmPin?.message && (
                          <div className="text-danger">
                            {errors?.confirmPin?.message}
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <>
                  {" "}
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={3}>
                        <Form.Label>{"Password"}</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          type="password"
                          placeholder={"Password"}
                          className={errors.password ? " is-invalid " : ""}
                          {...register("password", passwordCheck)}
                        />
                        {errors.password && errors.password.message && (
                          <label className="invalid-feedback text-left">
                            {errors.password.message}
                          </label>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col sm={12} className="mb-2 mb-md-3">
                    <Form.Group className="row">
                      <Col md={3}>
                        <Form.Label>{"Confirm Password"}</Form.Label>
                      </Col>
                      <Col md={9}>
                        <Form.Control
                          type="password"
                          placeholder={"Confirm Password"}
                          className={
                            errors.confirmPassword ? " is-invalid " : ""
                          }
                          {...register("confirmPassword", passwordCheckConfirm)}
                        />
                        {errors.confirmPassword &&
                          errors.confirmPassword.message && (
                            <label className="invalid-feedback text-left">
                              {errors.confirmPassword.message}
                            </label>
                          )}
                        {password_same === false &&
                          errors.confirmPassword !== "" && (
                            <label className="invalid-feedback text-left">
                              Password does not match.
                            </label>
                          )}
                      </Col>
                    </Form.Group>
                  </Col>
                </>
              )}
              {props.slug == "sub_owner" && (
                <Col sm={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Commission (%)</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="number"
                        min="10"
                        max="100"
                        placeholder="Enter commission"
                        className={errors.commission ? " is-invalid " : ""}
                        {...register("commission", {
                          required: "Please enter commission",
                        })}
                      />
                      {errors.commission && errors.commission.message && (
                        <label className="invalid-feedback text-left">
                          {errors.commission.message}
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              )}

              {console.log("props.slug", props.slug)}
              {(props.slug == "super_agent" || props.slug == "super_admin") && (
                <Col sm={12} className="mb-2 mb-md-3">
                  <Form.Group className="row">
                    <Col md={3}>
                      <Form.Label>Commission (%)</Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="number"
                        min="10"
                        max={props?.user?.saCutShare}
                        autoComplete="off"
                        placeholder="Enter commission"
                        className={errors.commission ? " is-invalid " : ""}
                        {...register("commission", {
                          required: "Please enter commission",
                        })}
                      />
                      {errors.commission && errors.commission.message && (
                        <label className="invalid-feedback text-left">
                          {errors.commission.message}
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              )}

              <Col sm={12} className="mb-2 mb-md-3">
                <Form.Group className="row">
                  <Col md={3}>
                    <Form.Label>Select Time Zone</Form.Label>
                  </Col>
                  <Col md={9}>
                    <Form.Select
                      aria-label="Default select example"
                      className={errors.timeZone ? " is-invalid " : ""}
                      {...register("timeZone", {
                        required: "Please select time zone",
                      })}
                    >
                      {/* <option value="">Please select time zone</option> */}
                      {metaData &&
                        metaData["timeZoneList"] &&
                        metaData["timeZoneList"].map((item, key) => (
                          <option value={item.tz} key={key}>
                            {item.tz}
                          </option>
                        ))}
                    </Form.Select>
                    {errors.timeZone && errors.timeZone.message && (
                      <label className="invalid-feedback text-left">
                        {errors.timeZone.message}
                      </label>
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mt-3 text-center">
              <Button
                type="submit"
                disabled={isLoader ? true : false}
                className="theme_dark_btn px-5"
              >
                Create
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Add;

const number = [
  31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
  50,
];
