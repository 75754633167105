import React, { useEffect, useRef, useState, useContext } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import OtpInput from "react18-input-otp";
import { isEmpty } from "lodash";
import { apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
const ForgotPassword = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { errors },
  } = useForm();

  const [step, setStep] = useState(1);
  const [data, setData] = useState({});

  const sendOTP = async (body) => {
    const { data: response_data } = await apiPost(apiPath.sendOTP, body);
    if (response_data?.success) {
      if (!response_data?.results?.isPhoneNumber) {
        toast.error(
          "Your phone number is not linked. Please contact to support team"
        );
      } else {
        setData({
          username: body?.username,
          phone: response_data?.results?.phone,
        });
        setStep(2);
      }
    } else {
      toast.error(response_data?.message);
    }
  };
  const resendOTP = async () => {
    const { data: response_data } = await apiPost(apiPath.sendOTP, {
      username: data?.username,
    });
    if (response_data?.success) {
      toast.success("Resend OTP");
    } else {
      toast.error(response_data?.message);
    }
  };
  const onVerfiyOTP = async (body) => {
    const { data: response_data } = await apiPost(apiPath.otpVerify, {
      otp: body.otp,
      phone: data?.phone,
    });
    if (response_data?.success) {
      toast.success(response_data?.message);
      setData({ ...data, otp: body?.otp });
      setStep(3);
    } else {
      toast.error(response_data?.message);
    }
  };

  const onSubmitOTP = async (body) => {
    const { data: response_data } = await apiPost(apiPath.forgotPassword, {
      otp: data?.otp,
      phone: data?.phone,
      password: body?.newPassword,
    });
    if (response_data?.success) {
      toast.success(response_data?.message);
      navigate("/login");
    } else {
      toast.error(response_data?.message);
    }
  };

  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const [counter, setCounter] = useState(59);

  useEffect(() => {
    if (step == 2) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, step]);

  return (
    <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <section className="login-sec">
        <Container fluid>
          <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="main-center">
              <div className="login_outer">
                <div className="login_left">
                  <span className="login_logo">
                    <img src="assets/images/melbets-logo.png" alt="" />
                  </span>
                </div>
                {step === 1 && (
                  <div className="form_outer">
                    <Form onSubmit={handleSubmit(sendOTP)}>
                      <h2 className="text-white">Forgot Password </h2>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="text"
                          placeholder="Username"
                          className={errors.username ? "is-invalid" : ""}
                          {...register("username", {
                            required: "Please enter Username",
                          })}
                        />
                        {errors.username && errors.username.message && (
                          <label className="invalid-feedback text-left">
                            {errors.username.message}
                          </label>
                        )}
                      </Form.Group>
                      <Button type="submit" className="green-btn theme-btn">
                        Reset
                        <span>
                          <img src="assets/images/loginicon.svg" />
                        </span>
                      </Button>
                    </Form>
                  </div>
                )}
                {step === 2 && (
                  <div className="form_outer">
                    <Form onSubmit={handleSubmit(onVerfiyOTP)}>
                      <h2 className="text-white mb-3">OTP Verify</h2>
                      <h6 className="text-white mb-3">Phone : +{data?.phone}</h6>
                      <Form.Group className="mb-2">
                        <div className="form_outer_otp">
                          <button
                            className="text-black"
                            onClick={() => {
                              if (counter === 0) {
                                setCounter(59);
                                resendOTP();
                              }
                            }}
                            disabled={counter !== 0}
                          >
                            Resend OTP{" "}
                            {counter !== 0 && `in ${counter} seconds`}
                          </button>
                        </div>
                        <Controller
                          className="form-group d-flex"
                          control={control}
                          name="otp"
                          rules={{
                            required: "Please enter 4 digit OTP",
                            validate: (value) => {
                              if (value?.toString()?.length == 4) {
                                return true;
                              } else {
                                return "Please enter 4 digit OTP";
                              }
                            },
                          }}
                          render={({ field: { ref, ...field } }) => (
                            <>
                              <OtpInput
                                {...field}
                                numInputs={4}
                                isInputNum={true}
                                inputExtraProps={{
                                  ref,
                                  required: true,
                                  autoFocus: true,
                                }}
                                shouldAutoFocus={true}
                                inputStyle={{
                                  width: "88%",
                                  height: "52px",
                                  borderRadius: "7px",
                                  border: "1px solid #ced4da",
                                }}
                                separator={<span> </span>}
                              />
                            </>
                          )}
                        />
                        {errors?.otp?.message && (
                          <div
                            className="text-danger"
                            style={{ marginTop: "5px" }}
                          >
                            {errors?.otp?.message}
                          </div>
                        )}
                      </Form.Group>
                      <Button type="submit" className="green-btn theme-btn">
                        Verify
                        <span>
                          <img src="assets/images/loginicon.svg" />
                        </span>
                      </Button>
                    </Form>
                  </div>
                )}

                {step === 3 && (
                  <div className="form_outer">
                    <Form onSubmit={handleSubmit(onSubmitOTP)}>
                      <h2 className="text-white mb-3">Change Password</h2>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className={errors.newPassword ? "is-invalid" : ""}
                          {...register("newPassword", {
                            required: "Please enter password",
                          })}
                        />
                        {errors.newPassword && errors.newPassword.message && (
                          <label className="invalid-feedback text-left">
                            {errors.newPassword.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          className={
                            errors.confirmPassword ? " is-invalid " : ""
                          }
                          {...register("confirmPassword", {
                            required: {
                              value: true,
                              message: "Please enter confirm password",
                            },
                            validate: (value) => {
                              if (value === "") {
                                return true;
                              }
                              if (!isEmpty(watch("newPassword"))) {
                                if (value === watch("newPassword")) {
                                  return true;
                                } else {
                                  return "Password and confirm password does not match.";
                                }
                              }
                            },
                            maxLength: {
                              value: 16,
                              message: "Maximum length must be 16.",
                            },
                          })}
                        />
                        {errors.confirmPassword &&
                          errors.confirmPassword.message && (
                            <label className="invalid-feedback text-left">
                              {errors.confirmPassword.message}
                            </label>
                          )}
                      </Form.Group>
                      <Button type="submit" className="green-btn theme-btn">
                        Verify
                        <span>
                          <img src="assets/images/loginicon.svg" />
                        </span>
                      </Button>
                    </Form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default ForgotPassword;
